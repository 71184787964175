import { Controller } from "@hotwired/stimulus"

export default class PickerController extends Controller {
  static targets = ['resultsContainer', 'searchContainer']

  connect() {
    this.initializeBlurHandling()
    this.boundPositionDropdown = this.positionDropdown.bind(this)
  }

  initializeBlurHandling() {
    this.mouseInContainer = false

    this.resultsContainerTarget.addEventListener('mouseenter', () => {
      this.mouseInContainer = true
    })

    this.resultsContainerTarget.addEventListener('mouseleave', () => {
      this.mouseInContainer = false
    })

    this.outsideClickHandler = (e) => {
      if (!this.resultsContainerTarget.contains(e.target) &&
          !this.searchContainerTarget.contains(e.target) &&
          !this.mouseInContainer) {
        this.hideElement(this.resultsContainerTarget)
      }
    }

    document.addEventListener('mousedown', this.outsideClickHandler)
  }

  disconnect() {
    document.removeEventListener('mousedown', this.outsideClickHandler)
  }

  debouncedInputHandler(event) {
    if (event.target.value.length >= this.minLengthValue) {
      this.search()
    }
  }

  blurHandler(event) {
    if (this.mouseInContainer) {
      return
    }

    if (!this.resultsContainerTarget.contains(event.relatedTarget) &&
        !this.searchContainerTarget.contains(event.relatedTarget)) {
      this.hideElement(this.resultsContainerTarget)
      this.queryTarget.value = ""
    }

    event.preventDefault()
  }

  hideElement(elem) {
    elem.classList.remove("visible")
    elem.classList.add("hidden")
    window.removeEventListener('scroll', this.boundPositionDropdown)
    window.removeEventListener('resize', this.boundPositionDropdown)
  }

  showElement(elem) {
    elem.classList.remove("hidden")
    elem.classList.add("visible")
    this.positionDropdown()
    window.addEventListener('scroll', this.boundPositionDropdown)
    window.addEventListener('resize', this.boundPositionDropdown)
  }

  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }

  positionDropdown() {
    const rect = this.searchContainerTarget.getBoundingClientRect()
    const modal = this.searchContainerTarget.closest('[data-picker-modal-locator="true"]')
    const modalRect = modal ? modal.getBoundingClientRect() : null
    if (modalRect) {
      this.resultsContainerTarget.style.top = `${rect.bottom - modalRect.top}px`
      this.resultsContainerTarget.style.left = `${rect.left - modalRect.left}px`
    } else {
      this.resultsContainerTarget.style.top = `${rect.bottom}px`
      this.resultsContainerTarget.style.left = `${rect.left}px`
    }
    this.resultsContainerTarget.style.width = `${rect.width}px`
  }
}
